<template>
  <exc-button
    class="button-with-arrow"
    color="main"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />

    <div v-if="!hideIcon" class="button-with-arrow__arrow">
      <start-arrow-icon />
    </div>
  </exc-button>
</template>

<script>
import { ExcButton } from '@/components/common'

export default {
  components: {
    ExcButton,
    StartArrowIcon: () => import('@/assets/img/welcome/start-arrow.svg?inline'),
  },
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.button-with-arrow {
  ::v-deep span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: toRem(12px);
  }

  @include bp(m) {
    &:hover div {
      // max-width: toRem(60px);
      width: toRem(60px);
    }
  }

  &__arrow {
    transition-duration: 0.25s;
    max-width: toRem(60px);
    width: toRem(48px);
    background: color('color-01');
    color: color('color-04');
    border-radius: toRem(230px);

    height: toRem(24px);
    width: toRem(50px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      max-width: toRem(32px);
    }
  }
}
</style>
